import { createSelector } from '@reduxjs/toolkit';

export const selectFiles    = (state) => state.upload.files;
export const selectName     = (state) => state.upload.name;
export const selectProgress = (state) => state.upload.progress;
export const selectError    = (state) => state.upload.error;
export const selectFileTree = (state) => state.upload.uploadData;
export const selectGroup    = (state) => state.upload.group;

export const selectIgnoreUntaggedSerieses = (state) => {
  return state.upload.ignoreUntagged;
}

export const selectCompressionProgress = (state) => {
  return state.upload.compressionProgress;
}

export const selectParsingProgress = (state) => {
  return state.upload.parsingProgress;
}

export const selectIsUploadStarted = (state) => {
  return state.upload.progress !== undefined || state.upload.compressionProgress !== undefined;
}

export const selectPatientData = (state, idx) => {
  return state.upload.uploadData[idx];
}

export const selectUploadPatientsCount = (state) => {
  return state.upload.uploadData?.length || 0;
}

export const selectUploadPatientIds = createSelector(
  selectUploadPatientsCount,
  count => new Array(count).fill(0).map((_, i) => i)
);

export const selectUploadData = createSelector(
  [ selectFileTree, selectIgnoreUntaggedSerieses ],
  (tree, ignoreUntagged) => tree?.reduce((a, p) => {
    a.push({
      patient: {
        ...p.patient,
        tags: p.files.reduce((b, s) => {
          if(s.tag) {
            if(s.tag in b) b[s.tag].push({ series: s.key, dataTags: s.dataTags })
            else b[s.tag] = [{ series: s.key, dataTags: s.dataTags }];
          }
          return b;
        }, {})
      },
      files: p.files.reduce((b, s) => {
        if(!ignoreUntagged || s.tag) b.push(...s.children);
        return b;
      }, [])
    });
    return a;
  }, []).filter(p => p.files.length > 0)
);

export const selectUploadFilesCount = createSelector(
  [ selectFileTree, selectIgnoreUntaggedSerieses ],
  (tree, ignoreUntagged) => tree?.reduce((a, p) => {
    return a + p.files.reduce((b, s) => {
      return (!ignoreUntagged || s.tag) ? (b + s.children.length) : b
    }, 0);
  }, 0) || 0
);

export const selectUploadFilesSize = createSelector(
  [ selectFileTree, selectIgnoreUntaggedSerieses ],
  (tree, ignoreUntagged) => {
    return tree?.reduce((a, p) => {
      let size = 0;
      for(let i = 0; i < p.files.length; ++i) {
        if(ignoreUntagged && !p.files[i].tag) continue;
        size += p.files[i].children.reduce((b, c) => b + c.size, 0);
      }
      return a + size;
    }, 0) || 0;
  }
);

export const selectFilesSize = createSelector(
  selectFiles,
  (files) => files?.reduce((a, f) => a + f.size, 0)
);

export const selectIsDirUpload = createSelector(
  selectFiles,
  (files) => {
    if(!files || files.length === 0) return undefined;
    return files.length === 1 ? files[0].name.endsWith('.dcm') : true;
  }
);

export const selectHasDataErrors = state => state.upload.uploadData?.some(i => !!i.error)
