import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, Checkbox, Typography, Progress, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import prettyBytes from 'pretty-bytes';
import {
  faTrashCan,
  faUpLong,
  faInbox,
} from '@fortawesome/free-solid-svg-icons';
import {
  setFiles,
  setGroup,
  clearFiles,
  setUploadData,
  setParsingProgress,
  setIgnoreUntaggedSerieses,
} from '../../../redux/upload/upload.slice';
import {
  selectName,
  selectFiles,
  selectGroup,
  selectUploadFilesSize,
  selectUploadFilesCount,
  selectUploadPatientIds,
  selectParsingProgress,
  selectIgnoreUntaggedSerieses,
  selectHasDataErrors
} from '../../../redux/upload/upload.selectors';
import { useUploadRequest } from '../../../redux/upload/upload.api';
import { processDicoms, createFileTree } from '../../../modules/dcm/parser';
import GroupSelect from './GroupSelect';
import PatientEdit from './PatientEdit/PatientEdit';
import UploadFormStyled from './UploadForm.styled';

const UploadForm = ({ patientId, name, files, group, ignoreUntagged, totalCount, totalSize, patientIds, parsingProgress, hasDataErrors, ...props }) => {
  const dispatch = useDispatch();

  const [ onUpload ] = useUploadRequest();
  const [ addToGroup, setAddToGroup ] = useState(false);

  const isSinglePatient = useMemo(() => !!patientId, [patientId]);

  const onDropAccepted = useCallback(files => {
    if(files.length > 1 && !files.every(f => f.name.endsWith('.dcm'))) {
      message.error("All items must be DICOM images when selecting multiple files");
    } else {
      dispatch(setFiles(files));
    }
  }, [dispatch]);

  const onDropRejected = useCallback(() => {
    message.warning("Some files were rejected since they are not suitable for upload");
  }, [])

  const onDirUpload = useCallback(e => {
    const filteredFiles = [...e.target.files].filter(f => f.name.endsWith('.dcm'));
    if(filteredFiles.length === 0) {
      message.error("Selected directory does not contain DICOM images");
      return;
    }
    if(filteredFiles.length !== e.target.files.length) {
      message.warning("Some files were rejected since they are not suitable for upload");
    }
    dispatch(setFiles(filteredFiles));
  }, [dispatch]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: {
      'application/zip':   [ '.zip' ],
      'application/gzip':  [ '.tar.gz' ],
      'application/dicom': [ '.dcm' ]
    }
  });

  useEffect(() => {
    if(!files) return;
    processDicoms(files, p => dispatch(setParsingProgress(p)))
      .then(seriesData => {
        if(isSinglePatient && Object.keys(seriesData).length > 1) {
          message.error("Selected files contains multiple subjects");
          dispatch(setFiles(undefined))
        } else {
          createFileTree(seriesData).then(tree => {
            if(!tree || tree.length === 0) {
              message.error("No valid files found");
              dispatch(setFiles(undefined));
            } else {
              dispatch(setUploadData(tree));
            }
            dispatch(setParsingProgress(undefined));
          });
        }
      });
  }, [dispatch, files, isSinglePatient]);

  return (<UploadFormStyled>
    { (files && parsingProgress === undefined) && <div className="upload-header">
      <Typography.Text>
        Selected <b>{totalCount}</b> files ({prettyBytes(totalSize)}):
      </Typography.Text>
      { !isSinglePatient &&
        <Checkbox checked={addToGroup}
          onChange={e => {
            if(!e.target.checked) dispatch(setGroup(undefined));
            setAddToGroup(e.target.checked);
          }}
        >
          Create group / add to group
        </Checkbox>
      }
      { addToGroup &&
        <GroupSelect
          onChange={value => dispatch(setGroup(value))}
          value={group}
        />
      }
      <Checkbox checked={ignoreUntagged} onChange={e => {
        dispatch(setIgnoreUntaggedSerieses(e.target.checked));
      }}>
        Ignore untagged serieses
      </Checkbox>
      <Button onClick={() => {
        dispatch(clearFiles());
        dispatch(setGroup(undefined));
      }}>
        <FontAwesomeIcon icon={faTrashCan}/>
        Delete all
      </Button>
      <Button type="primary" disabled={totalCount === 0 || hasDataErrors} onClick={
        () => onUpload(patientId)
      }>
        <FontAwesomeIcon icon={faUpLong}/>
        Upload
      </Button>
    </div>}
    { (!files || files.length === 0) &&
      <div className="upload-dropzone">
        <div {...getRootProps()}>
          <input {...getInputProps()} id="file-upload"/>
          <FontAwesomeIcon icon={faInbox}/>
          {
            isDragActive
            ? <p> Drop the files here ...</p>
            : <p> Drag files here </p>
         }
        </div>
        <div style={{ visibility: isDragActive ? 'hidden' : 'visible' }}>
          <label htmlFor="file-upload">
            or click to select
            <b>files</b>
          </label>
          or
          <label htmlFor="dir-upload">
            <b>directory</b>
            <input id="dir-upload" type="file" webkitdirectory="true"
              style={{display: 'none'}}
              onChange={onDirUpload}
            />
          </label>
          <p>
            supported formats: .zip, .tar.gz, .dcm
          </p>
        </div>
      </div>
    }

    <div className="upload-filelist">
      { parsingProgress !== undefined && <>
        <Typography.Text> Reading files ... </Typography.Text>
        <Progress percent={parsingProgress}/>
      </>}
      { patientIds.map(id =>
        <PatientEdit key={id} id={id} showPersonalData={!isSinglePatient}/>
      )}
    </div>
  </UploadFormStyled>);
}

const mapState = (state, props) => ({
  name:            selectName(state),
  files:           selectFiles(state),
  group:           selectGroup(state),
  totalCount:      selectUploadFilesCount(state),
  totalSize:       selectUploadFilesSize(state),
  patientIds:      selectUploadPatientIds(state),
  parsingProgress: selectParsingProgress(state),
  ignoreUntagged:  selectIgnoreUntaggedSerieses(state),
  hasDataErrors:   selectHasDataErrors(state)
});

export default connect(mapState, null)(UploadForm);
