import React, { useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, Tag, Typography, Divider, Input, Space, theme, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFolder,
  faPaperclip,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import {
  faTrashCan
} from '@fortawesome/free-regular-svg-icons';
import {
  removePatientSeries,
  setPatientSeriesTag,
  addPatientSeriesDataTag,
  setPatientSeriesDataTag,
  removePatientSeriesDataTag
} from '../../../../redux/upload/upload.slice';
import FileTreeStyled, { FileTreeItemStyled } from './FileTree.styled';

const menuItems = [
  { key: 'structural', label: 'structural' },
  { key: 'functional', label: 'functional' },
  { key: 'tracts',     label: 'tracts'     },
  { key: 'unknown',    label: 'unknown'    }
];

const customTagMenuItems = [
  { key: 'default', label: 'default' },
  { key: 'pre', label: 'pre' },
  { key: 'post', label: 'post' }
];

const DataTypeTag = ({ tagMenu, tag, ...props }) => {
  return <Dropdown arrow trigger={['click']} menu={tagMenu}>
    <Tag color={tag && "green"}> { tag || 'unknown' } </Tag>
  </Dropdown>
}

const DataTagDropdown = ({ onSelect, defaultValue, ...props }) => {
  const { token } = theme.useToken();

  const [ open, setOpen ] = useState(false);
  const [ tag, setTag ] = useState(defaultValue || "")

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: 'none',
  };

  return <Dropdown arrow trigger={['click']}
    menu={{
      items: customTagMenuItems,
      onClick: ({ key }) => onSelect(key)
    }}
    open={open}
    onOpenChange={isOpen => {
      setOpen(isOpen)
      setTag("")
    }}
    dropdownRender={(menu) => <div style={contentStyle}>
      { React.cloneElement(menu, { style: menuStyle })}
      <Divider style={{ margin: 0 }}/>
      <Space style={{ padding: 8 }}>
        <Input
          placeholder="Custom tag..."
          value={tag}
          onChange={e => setTag(e.target.value)}
          maxLength={64}
          onPressEnter={e => {
            onSelect(e.target.value);
            setOpen(false);
          }}
        />
      </Space>
    </div>
  }>
    {props.children}
  </Dropdown>
}

const DataTag = ({ tag, onClick, onClose, ...props }) => {
  return <DataTagDropdown onSelect={onClick} defaultValue={tag}>
    <Tag closable color="blue" onClose={onClose}> {tag} </Tag>
  </DataTagDropdown>
}

const FileTreeItem = ({
  node, onExpand, onDelete, onTagChange,
  onDataTagAdd, onDataTagChange, onDataTagDelete,
  ...props
}) => {
  const [ hover, setHover ] = useState(false);

  const tagMenu = useMemo(() => {
    return node.children && ({
      items: menuItems,
      onClick: ({ key }) => onTagChange(node.key, key !== 'unknown' ? key : undefined)
    });
  }, [node.children, node.key, onTagChange]);

  return (<FileTreeItemStyled
    onMouseEnter={() => setHover(true)}
    onMouseLeave={() => setHover(false)}
  >
    <FontAwesomeIcon icon={node.children ? faFolder : faPaperclip}/>
    <span className="fti-title" onClick={() => onExpand(node.key)}>
      { node.title }
      { node.children &&
        <Typography.Text>
          {`[ ${node.children?.length} file${node.children?.length > 1 ? 's' : ''} ]`}
        </Typography.Text>
      }
    </span>
    { node.children && <>
      <span>
        <DataTypeTag tagMenu={tagMenu} tag={node.tag}/>
        { node.dataTags?.map((t, i) =>
          <DataTag key={t} tag={t}
            onClick={value => {
              if(node.dataTags.includes(value)) {
                message.warning("The series already has the specified tag")
              } else {
                onDataTagChange(node.key, i, value)
              }
            }}
            onClose={() => onDataTagDelete(node.key, i)}
          />
        )}
        { node.tag &&
          <DataTagDropdown onSelect={value => {
            if(node.dataTags?.includes(value)) {
              message.warning("The series already has the specified tag")
            } else {
              onDataTagAdd(node.key, value)}
            }
          }>
            <Tag style={{borderStyle: 'dashed'}} color="blue" icon={<FontAwesomeIcon icon={faPlus}/>}/>
          </DataTagDropdown>
        }
      </span>
      <FontAwesomeIcon
        icon={faTrashCan}
        style={{opacity: hover ? '100' : '0'}}
        onClick={() => onDelete(node.key)}
      />
    </>}
  </FileTreeItemStyled>);
}

const FileTree = ({ id, tree, ...props }) => {
  const dispatch = useDispatch();
  const [ expandedKeys, setExpandedKeys ] = useState([]);

  const onTreeExpand = useCallback(key => {
    setExpandedKeys(keys => keys.includes(key)
      ? keys.filter(k => k !== key)
      : [ ...keys, key ]
    );
  }, []);

  const onTreeNodeDelete = useCallback(key => {
    dispatch(removePatientSeries(id, key));
    setExpandedKeys(keys => keys.filter(k => k !== key));
  }, [id, dispatch]);

  const onTagChange = useCallback((series, tag) => {
    dispatch(setPatientSeriesTag(id, series, tag));
  }, [id, dispatch]);

  const onDataTagAdd = useCallback((series, dataTag) => {
    dispatch(addPatientSeriesDataTag(id, series, dataTag));
  }, [id, dispatch]);

  const onDataTagChange = useCallback((series, index, dataTag) => {
    dispatch(setPatientSeriesDataTag(id, series, index, dataTag));
  }, [id, dispatch]);

  const onDataTagDelete = useCallback((series, index) => {
    dispatch(removePatientSeriesDataTag(id, series, index));
  }, [id, dispatch]);

  return (<FileTreeStyled
    selectable={false}
    titleRender={node => <FileTreeItem
      node={node}
      onExpand={onTreeExpand}
      onDelete={onTreeNodeDelete}
      onTagChange={onTagChange}
      onDataTagAdd={onDataTagAdd}
      onDataTagChange={onDataTagChange}
      onDataTagDelete={onDataTagDelete}
    />}
    expandedKeys={expandedKeys}
    treeData={tree}
  />);
}

export default FileTree;
