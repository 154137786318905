import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  files:               undefined,
  uploadData:          [],
  dataErrors:          [],
  existingTags:        undefined,
  ignoreUntagged:      true,
  group:               undefined,
  progress:            undefined,
  error:               undefined,
  compressionProgress: undefined,
  parsingProgress:     undefined
};

const isString = (v) => typeof(v) === "string" || v instanceof String

const filterPatientFiles = (state, id, predicate) => {
  const applyFilter = (item) => item.files = item.files.filter(predicate);

  if(id) applyFilter(state.uploadData[id]);
  else state.uploadData.forEach(applyFilter);

  state.uploadData = state.uploadData.filter(item => item.files.length > 0);
  if(state.uploadData.length === 0) state.files = undefined;
}

const parsePatientMetadataTags = (tags, dataType) => {
  const t = tags[dataType]
  if(!t) return undefined;
  return isString(t)
    ? [{ tag: dataType, key: t, dataTags: ["default"], exists: true }]
    : t.map(i => ({ tag: dataType, key: i.file, dataTags: i.dataTags, exists: true }))
}

const _matchDataTags = (files, additionalTags) => {
  const existingTags = additionalTags
    ? Object.keys(additionalTags).reduce((a, k) => {
        const t = parsePatientMetadataTags(additionalTags, k);
        if(t) a.push(...t);
        return a;
      }, [])
    : []

  const allTags = [ ...files, ...existingTags ].reduce((a, s) => {
    if(!s.tag || s.tag === "unknown") return a;
    if(s.tag in a) a[s.tag].push(s)
    else a[s.tag] = [s]
    return a
  }, {});

  const multipleTags = Object.entries(allTags).reduce((a, [tag, data]) => {
    for(let i = 0; i < data.length - 1; ++i) {
      for(let j = i + 1; j < data.length; ++j) {
        let t1 = data[i].dataTags;
        let t2 = data[j].dataTags;
        if(!t1 || t1.length === 0) t1 = [ "default" ]
        if(!t2 || t2.length === 0) t2 = [ "default" ]

        const tagsIntersection = t1?.filter(e => t2.includes(e));
        if(tagsIntersection?.length > 0) a.push({
          s1: data[i],
          s2: data[j],
          tags: tagsIntersection.length === 1 && tagsIntersection[0] === "default" ? undefined : tagsIntersection,
        });
      }
    }
    return a;
  }, []);

  const getMessage = ({ s1, s2, tags: t}) => {
    if(t) {
      if(s1.exists) return `Patient series ${s1.key} has the same data tag(s) as ${s2.key}: ${t.join(', ')}`
      if(s2.exists) return `Patient series ${s2.key} has the same data tag(s) as ${s1.key}: ${t.join(', ')}`
      return `Series ${s1.key} and ${s2.key} have the same data tag(s): ${t.join(', ')}`
    } else {
      if(s1.exists) return `Patient series ${s1.key} has the same data type as ${s2.key}: ${s1.tag}. Please, change type, specify data tag or remove series`
      if(s2.exists) return `Patient series ${s2.key} has the same data type as ${s1.key}: ${s1.tag}. Please, change type, specify data tag or remove series`
      return `Series ${s1.key} and ${s2.key} have the same data type: ${s1.tag}. Please, change type or specify data tag`
    }
  }

  return multipleTags.length > 0 ? multipleTags.map(getMessage) : undefined;
}

const matchDataTags = (state, id) => {
  return _matchDataTags(state.uploadData[id].files, state.existingTags)
}

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    clearFiles: (state) => {
      state.files = undefined;
      state.uploadData = undefined;
    },
    setCompressionProgress: (state, action) => {
      state.compressionProgress = action.payload;
    },
    setUploadData: (state, action) => {
      state.uploadData = action.payload;
      if(state.existingTags && state.uploadData.length > 0) {
        state.uploadData[0].error = matchDataTags(state, 0)
      }
    },
    setPatientName: {
      reducer: (state, action) => {
        const { id, name } = action.payload;
        state.uploadData[id].patient.name = name;
      },
      prepare: (id, name) => ({ payload: { id, name }})
    },
    setPatientAge: {
      reducer: (state, action) => {
        const { id, age } = action.payload;
        state.uploadData[id].patient.age = age;
      },
      prepare: (id, age) => ({ payload: { id, age }})
    },
    setPatientSex: {
      reducer: (state, action) => {
        const { id, sex } = action.payload;
        state.uploadData[id].patient.sex = sex;
      },
      prepare: (id, sex) => ({ payload: { id, sex }})
    },
    setPatientSeriesTag: {
      reducer: (state, action) => {
        const { id, series, tag } = action.payload;
        state.uploadData[id].files = state.uploadData[id].files.map(i =>
          i.key === series
            ? { ...i, tag, dataTags: (!tag || tag === "unknown") ? [] : i.dataTags}
            : i
        );
        state.uploadData[id].error = matchDataTags(state, id)
      },
      prepare: (id, series, tag) => ({ payload: { id, series, tag }})
    },
    addPatientSeriesDataTag: {
      reducer: (state, action) => {
        const { id, series, tag } = action.payload;
        state.uploadData[id].files = state.uploadData[id].files.map(i => {
          return i.key === series
            ? { ...i, dataTags: i.dataTags ? [...i.dataTags, tag] : [tag] }
            : i
        });
        state.uploadData[id].error = matchDataTags(state, id)
      },
      prepare: (id, series, tag) => ({ payload: { id, series, tag }})
    },
    setPatientSeriesDataTag: {
      reducer: (state, action) => {
        const { id, series, index, tag } = action.payload;
        state.uploadData[id].files = state.uploadData[id].files.map(i => {
          return i.key === series
            ? { ...i, dataTags: i.dataTags.map((t, j) => j === index ? tag : t) }
            : i
        });
        state.uploadData[id].error = matchDataTags(state, id)
      },
      prepare: (id, series, index, tag) => ({ payload: { id, series, index, tag }})
    },
    removePatientSeriesDataTag: {
      reducer: (state, action) => {
        const { id, series, index } = action.payload;
        state.uploadData[id].files = state.uploadData[id].files.map(i => {
          return i.key === series
            ? { ...i, dataTags: i.dataTags.filter((_, j) => j !== index) }
            : i
        });
        state.uploadData[id].error = matchDataTags(state, id)
      },
      prepare: (id, series, index) => ({ payload: { id, series, index }})
    },
    setExistingPatientTags: (state, action) => {
      state.existingTags = action.payload;
    },
    removePatient: (state, action) => {
      state.uploadData.splice(action.payload, 1);
      if(state.uploadData.length === 0) state.files = undefined;
    },
    removePatientSeries: {
      reducer: (state, action) => {
        const { id, series } = action.payload;
        filterPatientFiles(state, id, s => s.key !== series);
        state.uploadData[id].error = matchDataTags(state, id);
      },
      prepare: (id, series) => ({ payload: { id, series }})
    },
    removeUntaggedSerieses: (state) => {
      filterPatientFiles(state, undefined, s => s.tag !== undefined);
    },
    setIgnoreUntaggedSerieses: (state, action) => {
      state.ignoreUntagged = action.payload;
    },
    setParsingProgress: (state, action) => {
      state.parsingProgress = action.payload;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setGroup: (state, action) => {
      state.group = action.payload;
    },
    clear: () => initialState
  }
});

export const {
  setFiles, clearFiles,
  setUploadData, removeFileTreeNode, removeUntaggedSerieses, setParsingProgress,
  setPatientName, setPatientAge, setPatientSex, setPatientSeriesTag, setExistingPatientTags,
  addPatientSeriesDataTag, setPatientSeriesDataTag, removePatientSeriesDataTag,
  removePatient, removePatientSeries, setIgnoreUntaggedSerieses,
  setCompressionProgress, setProgress, setError, setGroup, clear
} = uploadSlice.actions;

export default uploadSlice.reducer;
