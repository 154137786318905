import styled from 'styled-components';

const PatientEditStyled = styled.div`
  margin-bottom: 8px;

  ul {
    margin: 0;
    padding-left: 12px;
  }

  .pe-header {
    display: flex;
    align-items: center;

    .ant-divider {
      min-width: 90%;
      margin: 0;
    }

    > svg {
      margin-left: 8px;
      color: gray;
      transition: color 0.3s;
      cursor: pointer;

      :hover {
        color: darkgray;
        transition: color: 0.3s;
      }
    }
  }

  .pe-info {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    grid-gap: 4px 8px;

    margin-bottom: 4px;
  }
`;

export default PatientEditStyled;
