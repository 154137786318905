import styled from 'styled-components';

const GroupConfigStyled = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;

  > .ant-typography {
    grid-column: 1;
    margin-right: 8px;
    margin-bottom: 2px;
    line-height: 32px;
  }
`;

const AnalyzerConfigStyled = styled.div`
  margin-left: 24px;
  margin-top: 4px;
  margin-bottom: 4px;
  border: 1px solid lightgray;
  border-radius: 2px;
  background-color: aliceblue;
  padding: 8px;
  padding-bottom: 4px;

  > div {
    margin-top: 4px;
    display: grid;
    grid-template-columns: max-content auto;
    align-items: baseline;

    > .ant-typography {
      grid-column: 1;
      margin-right: 16px;
      margin-bottom: 2px;
      line-height: 32px;
    }

    .ant-checkbox-wrapper {
      grid-column: 2;
    }
  }
`;

const AnalyzerSubconfigStyled = styled.div`
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 8px;
  background-color: white;
  grid-column: 2;
  gap: 4px 8px;

  display: grid;
  grid-template-columns: max-content auto;
  align-items: baseline;

`;

const AnalyzerSelectorStyled = styled.div`
  display: grid;
  grid-template-columns: 120px auto;
  grid-template-rows: auto;
  /*margin-left: 32px;*/

  .ant-checkbox-wrapper {
    grid-column: 1;
  }

  > .ant-typography:first-child {
    grid-column: 1 / 3;
  }

  > .ant-typography {
    grid-column: 2;
  }

  > div {
    grid-column: 1 / 3;
  }

  .ant-btn {
    margin-top: 8px;
    span {
      margin-left: 8px;
    }
  }
`;

export {
  AnalyzerSelectorStyled,
  AnalyzerConfigStyled,
  AnalyzerSubconfigStyled,
  GroupConfigStyled
};
