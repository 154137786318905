import { createSelector } from '@reduxjs/toolkit';
import { cache } from '@cornerstonejs/core';
import { ToolGroupManager, Enums } from '@cornerstonejs/tools';
import { DATA_TYPE, AXIS } from '../../constants';
import { TOOL_GROUP_ID } from '../../redux/viewer/toolManager';
import { forEachLayer, getLayer } from './viewer.slice';

export const selectAnalysisId = (state) => state.viewer.analysisId;

export const selectLayers = (state) => {
  return state.viewer.layers;
}

export const selectActiveViewportLayers = createSelector(
  [
    state => state.viewer.activeViewport,
    state => state.viewer.viewports
  ],
  (avp, vps) => vps.find(v => v.id === avp).layers
)

export const selectViewportGridRows = (state) => state.viewer.viewportGridRows;
export const selectViewportGridCols = (state) => state.viewer.viewportGridCols

export const selectViewportsConfig = (state) => state.viewer.viewports;

export const selectViewportAxis = (state, id) => {
  return state.viewer.viewports.find(v => v.id === id)?.axis;
}

export const selectViewportConfig = (state, id) => {
  return state.viewer.viewports.find(v => v.id === id);
}

export const selectActiveViewport = (state) => state.viewer.activeViewport;

export const selectIsBottomRightViewport = (state, id) => {
  const vc = state.viewer.viewports.length - 1;
  return vc < 0 ? false : state.viewer.viewports[vc].id === id;
}

export const selectViewportIds = createSelector(
  selectViewportsConfig, viewports => viewports.map(v => v.id)
);

export const selectVolumeViewportIds = createSelector(
  selectViewportsConfig,
  viewports => viewports.filter(v => v.axis === AXIS.VOLUME).map(v => v.id)
);

export const selectSliceViewportIds = createSelector(
  selectViewportsConfig,
  viewports => viewports.filter(v => v.axis !== AXIS.VOLUME).map(v => v.id)
);

export const selectDataLayers = (state) => {
  const layers = [];
  forEachLayer(state.viewer, l => {
    if(!l.error && l.volumeId && !l.hidden && (l.hasColorSettings || l.type === DATA_TYPE.SEGMENTATION || l.type === DATA_TYPE.CONN_GROUP)) {
      layers.push(l);
    }
  })
  return layers;
}

export const selectHasTractsLayers = (state) => {
  return !!state.viewer.layers.find(l => l.type === DATA_TYPE.TRACTOGRAPHY);
}

export const selectVisualOptions = (state, index) => {
  return getLayer(state.viewer, index)?.options;
}

export const selectValidVolumeIds = (state) => {
  const ids = [];
  forEachLayer(state.viewer, l => {
    if(!l.error && l.volumeId) ids.push(l.volumeId);
  });
  return ids;
}

export const selectLayerLoadingOptions = (state) => {
  const options = [];
  forEachLayer(state.viewer, l => {
    if(!l.error) options.push(l.loadingOptions);
  });
  return options;
}

export const selectValidThicknessVolumeId = state => {
  const layer = state.viewer.layers.find(l => {
    return l.type === DATA_TYPE.THICKNESS && !l.error
  });
  return layer?.volumeId;
}

export const selectVolumeDataRange = (state, index) => {
  const { volumeId } = getLayer(state.viewer, index);
  return volumeId
    ? cache.getVolume(volumeId)?.metadata?.dataRange
    : undefined;
}

export const selectLoadProgress = createSelector(
  [ state => state.viewer.progress, state => state.viewer.filesToLoad ],
  (progress, numLoading) => {
    return numLoading
      ? Math.trunc(Object.values(progress).reduce((a, i) => a + i, 0) / numLoading)
      : undefined;
  }
);

export const selectHasLayers = state => state.viewer.layers?.length > 0

export const selectIsDataLoaded = createSelector(
  [ state => state.viewer.progress, state => state.viewer.layers.length > 0 ],
  (progress, hasLayers) => {
    if(!hasLayers || Object.keys(progress).length === 0) return undefined;
    return Object.values(progress).every(p => p === 100);
  }
);

export const selectIsViewportsCreated = (state) => {
  return Object.values(state.viewer.viewports).every(v => v.created);
}

export const selectIsViewportsInitialized = createSelector(
  [ state => state.viewer.viewports ],
  (viewports) => viewports.every(v => v.initialized)
);

export const selectVolumeMetadata = (state, index) => {
  const { volumeId } = getLayer(state.viewer, index);
  return volumeId ? cache.getVolume(volumeId)?.metadata : undefined;
}

export const selectCameraPos = (state) => state.viewer.cameraPos;

export const selectRenderingEngine = (state) => {
  return state.viewer.renderingEngine;
}

export const selectToolGroup = (state, id) => {
  return ToolGroupManager.getToolGroup(id);
}

export const selectSliceTools = (state) => {
  return selectToolGroup(state, TOOL_GROUP_ID.SLICE);
}

export const selectVolumeTools = (state) => {
  return selectToolGroup(state, TOOL_GROUP_ID.VOLUME);
}

export const selectIsToolsCreated = (state) => {
  return Object.values(TOOL_GROUP_ID).every(id => !!selectToolGroup(state, id));
}

export const selectMouseLeftTool = (state) => {
  return state.viewer.activeTools[Enums.MouseBindings.Primary];
}

export const selectMouseRightTool = (state) => {
  return state.viewer.activeTools[Enums.MouseBindings.Secondary];
}

export const selectProbeToolTarget = (state) => {
  return state.viewer.probeTargetId;
}
