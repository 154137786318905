import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Alert, Divider, Input, InputNumber, Typography, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { selectPatientData } from '../../../../redux/upload/upload.selectors';
import {
  setPatientName,
  setPatientAge,
  setPatientSex,
  removePatient
} from '../../../../redux/upload/upload.slice';
import FileTree from '../FileTree/FileTree';
import PatientEditStyled from './PatientEdit.styled';

const { Text } = Typography;

const PatientEdit = ({ id, data, showPersonalData, ...props }) => {
  const dispatch = useDispatch();
  const { patient, files, error } = data;

  return (<PatientEditStyled>
    { showPersonalData && <>
      <div className={"pe-header"}>
        <Divider plain orientation="left">
          <b>Patient {id + 1}</b>
        </Divider>
        <FontAwesomeIcon
          icon={faXmark}
          onClick={() => dispatch(removePatient(id))}
        />
      </div>
      <div className={"pe-info"}>
        <Text> Name </Text>
        <Text> Age </Text>
        <Text> Sex </Text>
        <Input
          onChange={e => dispatch(setPatientName(id, e.target.value))}
          value={patient.name}
        />
        <InputNumber min={1} max={150} style={{ width: '100%' }}
          onChange={value => dispatch(setPatientAge(id, value))}
          value={patient.age}
        />
        <Select
          options={[
            { value: 'M', label: 'Male' },
            { value: 'F', label: 'Female' },
            { value: 'U', label: 'Unset' }
          ]}
          onChange={value => dispatch(setPatientSex(id, value))}
          value={patient.sex}
        />
      </div>
    </>}
    { error &&
      <Alert type="error" showIcon message={
        (typeof(error) === "string" || error instanceof String)
          ? error
          : error.length === 1
            ? error[0]
            : <ul> { error.map((e, i) => <li key={i}>{e}</li>) } </ul>
        }
      />
    }
    <Text> Files </Text>
    <FileTree id={id} tree={files}/>
  </PatientEditStyled>)
}

const mapState = (state, props) => ({
  data: selectPatientData(state, props.id)
});

export default connect(mapState, null)(PatientEdit);
