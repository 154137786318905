import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button, Input, InputNumber, Tag, Typography, Select, Spin, Alert, Collapse
} from 'antd';
import PatientStyled from './Patient.styled';
import { STATUS_ICON } from '../../../../constants';
import { useDatasetQuery } from '../../../../redux/datasets/datasets.api';
import LogView from './LogView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const { Title, Text } = Typography;

const Patient = (props) => {
  const navigate = useNavigate();

  const { id } = useParams();
  const { data, isSuccess, isError } = useDatasetQuery(id);

  const tags = useMemo(() => {
    if(!data?.metadata) return undefined;
    const tags = Object.entries(data.metadata.tags);
    return data.metadata.serieses.reduce((a, s) => {
      tags.forEach(([k, v]) => {
        if(typeof(v) === "string") {
          if(v.includes(s)) a[s] = [k];
        } else {
          const extraTags = v.find(({ file }) => file.includes(s))?.dataTags
          if(extraTags) a[s] = [k, ...extraTags];
        }
      });
      return a;
    }, {});
  }, [data]);

  if(isError) {
    return <Alert showIcon type='error' message="Unabe to load patient"/>
  } else if(!isSuccess) {
    return <Spin/>
  } else if(data.status === 'processing') {
    return <Alert showIcon type='info' message="Patient data is being processed"/>
  }

  return (<PatientStyled>
    <Title level={4}>
      {STATUS_ICON[data.status]}
      {data.name}
    </Title>

    <div className="pi-main">
      <div className={"pi-general"}>
        <Text> Name </Text>
        <Text> Age </Text>
        <Text> Sex </Text>
        <Input
          /*onChange={e => dispatch(setPatientName(id, e.target.value))}*/
          value={data.name}
        />
        <InputNumber min={1} max={150} style={{ width: '100%' }}
          /*onChange={value => dispatch(setPatientAge(id, value))}*/
          value={data.age}
        />
        <Select
          options={[
            { value: 'M', label: 'Male' },
            { value: 'F', label: 'Female' },
            { value: 'U', label: 'Unset' }
          ]}
          /*onChange={value => dispatch(setPatientSex(id, value))}*/
          value={data.sex}
        />
      </div>
      { data?.metadata?.warnings?.length > 0 &&
        <Alert type='warning' showIcon message={data.metadata.warnings.join('\n')}/>
      }
      { data?.metadata?.errors?.length > 0
        ? <Alert type='error' showIcon message={data.metadata.errors.join('\n')}/>
        : (data.error && <Alert type='error' showIcon message={data.error}/>)
      }

      <Collapse ghost items={[
        {
          key: '1',
          label: "Show logs",
          children: <LogView id={id}/>
        }
      ]}/>

      <Text> Groups </Text>
      { data.groups?.length > 0
        ? <div className="pi-groups">
            { data.groups?.map(g => <div key={g.id}> {g.name} </div>) }
          </div>
        : <Alert showIcon type="info" message="The patient is not a member of any group"/>
      }

      <span className="pi-serieses-header">
        <Text> Serieses </Text>
        <Button type="primary"
          icon={<FontAwesomeIcon icon={faPlus}/>}
          onClick={() => navigate(`../upload/${id}`)}
        >
          Add serieses
        </Button>
      </span>
      { data.metadata
        ? <div className="pi-serieses">
          { data.metadata.serieses?.map(s =>
            <div key={s}>
              <span>{ s }</span>
              { tags[s]
                ? tags[s].map(t => <Tag key={t} color="blue">{t}</Tag>)
                : <Tag> unknown </Tag>
              }
            </div>)
          }
          </div>
        : <Alert type='error' showIcon
            message={data.metadataError?.status === 404
              ? 'Patient metadata is missing'
              : 'Unable to load patient metadata'
            }
          />
      }
    </div>
  </PatientStyled>);
}

export default Patient;
