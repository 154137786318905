import React, { useEffect, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Alert, Spin } from 'antd';
import UploadForm from './UploadForm';
import UploadProgress from './UploadProgress';
import UploadStyled from './Upload.styled';
import { selectIsUploadStarted } from '../../../redux/upload/upload.selectors';
import { useDatasetQuery } from '../../../redux/datasets/datasets.api';
import { clear, setExistingPatientTags } from '../../../redux/upload/upload.slice';

const Upload = ({ isUploading, ...props }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { data, isError } = useDatasetQuery(id, { skip: !id });

  useEffect(() => {
    if(data) dispatch(setExistingPatientTags(data.metadata?.tags));
    return () => dispatch(setExistingPatientTags(undefined));
  }, [dispatch, data]);

  useEffect(() => {
    return () => dispatch(clear());
  }, [dispatch]);

  const headerText = useMemo(() => {
    if(!id) return "Upload new dataset"
    else if(data) return "Add new series to " + data.name
    return ""
  }, [id, data])

  return (<UploadStyled>
    { id && isError &&
      <Alert showIcon type='error'
        message="Unable to load patient data"
      />
    }
    { (id && !data)
      ? <Spin/>
      : <>
          <Typography.Title level={4}> {headerText} </Typography.Title>
          { isUploading
            ? <UploadProgress/>
            : <UploadForm patientId={id}/>
          }
        </>
    }
  </UploadStyled>);
}

const mapState = (state, props) => ({
  isUploading: selectIsUploadStarted(state)
})

export default connect(mapState, null)(Upload);
